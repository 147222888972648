<template>
    <content-wrapper>
        <v-row>
            <v-col cols="12">
                <course-head
                    :item="item"
                    total-capacity
                >
                    <template #actions>
                        <div class="d-flex align-center">
                            <w-actions-menu
                                :items="actionsMenuItems"
                            />
                        </div>
                    </template>
                </course-head>
            </v-col>
            <v-col cols="12">
                <course-groups
                    :uuid="uuid"
                />
            </v-col>
            <v-col cols="12">
                <course-capacity-amounts
                    :uuid="uuid"
                />
            </v-col>
        </v-row>
        <w-aside-drawer
            v-model="isForm"
            :title="$trans('Update')"
        >
            <div class="pa-4">
                <course-form
                    :item="item"
                    @updated="onUpdatedItem"
                />
            </div>
        </w-aside-drawer>
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import { deleteCourse, getCourse } from '@apps/school/api/courses'
import CourseGroups from '@apps/school/components/Course/CourseGroups'
import ConfirmationSets from '@/utils/ConfirmationSets'
import CourseForm from '@apps/school/components/Course/CourseForm'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
import CourseCapacityAmounts from '@apps/school/components/Course/CourseCapacityAmounts'
import CourseHead from '@apps/school/components/Course/CourseHead'
export default {
    name: 'CourseDetailsManage',
    components: { CourseHead, CourseCapacityAmounts, CourseForm, CourseGroups, ContentWrapper },
    mixins: [ mobileBreakpointChecker ],
    data() {
        return {
            item: null,
            isForm: false,
            levels: [],
            groups: [],
        }
    },
    computed: {
        uuid() {
            return this.$route.params.uuid
        },
        actionsMenuItems() {
            return [
                {
                    text: this.$trans('Edit'),
                    icon: 'EDIT',
                    color: 'success',
                    action: 'onEditItem',
                    on: {
                        click: this.onEditItem
                    }
                },
                {
                    text: this.$trans('Delete'),
                    icon: 'DELETE',
                    color: 'error',
                    action: 'onDeleteItem',
                    on: {
                        click: this.onDeleteItem
                    }
                }
            ]
        }
    },
    mounted() {
        this.fetchCourse(true)
    },
    methods: {
        fetchCourse(initAttachemnts) {
            const payload = {
                with: 'department:title,uuid'
            }

            getCourse(this.$route.params.uuid, payload)
                .then(response => {
                    this.item = response.data.data
                })
        },
        onEditItem() {
            this.isForm = true
        },
        onUpdatedItem() {
            this.isForm = false
            this.fetchCourse()
        },
        async onDeleteItem() {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet({
                title: `${this.$trans('Delete')} ${this.item.title}`
            }))

            if (!isConfirm) {
                return
            }

            try {
                await deleteCourse(this.uuid)
                await this.$router.push({ name: 'manage.school.courses' })
            } catch (error) {}
        }
    }
}
</script>

<style lang=scss>

</style>
